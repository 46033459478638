/* Comment out Atatus - ticket TO-181 */
// import * as atatus from "atatus-spa";
// atatus.config("5f6c13e0b22744979a7bc9626027aef5").install();

import 'babel-polyfill';
import 'element-remove';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import Transitions from 'vue2-transitions';
import VueScrollTo from 'vue-scrollto';
import AnimateCss from 'animate.css';
import VueApexCharts from 'vue-apexcharts';
import VueConfetti from 'vue-confetti';
import mixpanel from 'mixpanel-browser';
import VueIntro from 'vue-introjs';
import VueScript2 from 'vue-script2';
import 'intro.js/introjs.css';
import './registerServiceWorker';
import { initializeSienna } from './libraries/sienna.js';

(async function () {
	if (localStorage.getItem('accessibility_enabled') === 'true') {
		await initializeSienna();
	}
})();

if (process.env.VUE_APP_MIXPANEL == 'production') {
	mixpanel.init('e94fca12de6188275763c1f5ae96aa9d');
}

Vue.prototype.$http = axios;
Vue.prototype.$mixpanel = mixpanel;

Vue.use(Transitions);
Vue.use(VueScrollTo);
Vue.use(AnimateCss);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.use(VueConfetti);
Vue.use(VueIntro);
Vue.use(VueScript2);

Vue.config.productionTip = false;
new Vue({
	router,
	vuetify,
	store,
	render: (h) => h(App),
}).$mount('#app');

function trackVueExceptions() {
	if (!Vue || !Vue.config) return; // quit if Vue isn't on the page

	/* Comment out Atatus - ticket TO-181 */
	// if (!atatus || !atatus.config) return;  // quit if atatus isn't on the page

	/* Comment out Atatus - ticket TO-181 
  var prevHandler = Vue.config.errorHandler;
  Vue.config.errorHandler = function VueErrorHandler(error, vm) {
    atatus.notify(error, {
      extra: {
        componentName: vm.$options.name,
        propsData: vm.$options.propsData,
      },
    });

    if (typeof console !== "undefined" && typeof console.error === "function") {
      console.error(`Error from component ${vm.$options.name} : `, error);
      console.log(`Component props : `, vm.$options.propsData);
    }

    if (typeof prevHandler === "function") {
      prevHandler.call(this, error, vm);
    }
  };
  */
}

trackVueExceptions();
